.rename-watchlist {
  display: block;
}

.rename-title {
  font-size: 18px;
  font-weight: bold;
  margin: 18px;
  display: flex;
  justify-content: center;
  color:rgb(57, 58, 147)
}

.rename-watchlist-input {
  margin-left: 80px;
  font-weight: 600;
}

.rename-watchlist-buttons {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.rename-save, .rename-cancel {
  border: none;
  background: transparent;
  font-size: 16px;
  cursor: pointer;
  font-family: cursive;
  font-weight: 600;
  margin-right: 20px;
}

.rename-save:hover, .rename-cancel:hover {
  background-color: rgb(120, 152, 241);
}
