.home-page {
  display: flex;
  justify-content: center;
  background-color: lightgreen;
  min-height: 90vh;
}

.money {
  display: block;
  margin-top: 150px;
  margin-right: 150px;
}

.click-me {
  background-color:transparent;
  color: rgb(255, 255, 255);
  font-size: 38px;
  font-weight: 550;
  border: none;
  font-family: "Comic Sans MS", sans-serif;
  margin-top: 150px;
  margin-left: 30px;
  flex-wrap: wrap;
  width: 400px;
  height: fit-content;
  display: flex;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.footer {
  background-color: lightgreen;
  padding:10px 0px;
  display: flex;
  justify-content: center;
}

.footer-text {
  display: flex;
  align-items: center;
}

.footer-text > p {
  margin:0px;
  font-size: 20px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
