.login-whole-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-page {
  display: flex;
  font-family: "Capsule Sans Display",system-ui,"Segoe UI",Helvetica,Arial,sans-serif;
}

.login-form {
  display:block;
  width: 450px;
  height: 718px;
  padding: 40px 56px;
  border: 2px solid #ccc;
  border-radius: 10px;
  background-color: #d3e7cd;
  box-shadow: 0px -12px 24px rgb(0 0 0 0.02), 0px 12px 24px rgb(0 0 0 0.06);
}

.login-page-buttons {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.login-page-login-button, .signup-page-signup-button {
  margin-right: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 80px;
  height: 40px;
  border-radius: 20px;
  color:rgb(61, 133, 6);
  font-weight: 700;
}

.login-page-login-button:hover, .signup-page-signup-button:hover {
  background-color: #1e6b04;
  color: #fff;
}

.login-demo-user, .login-page-signup-button {
  background-color: #d3e7cd;
  border:none;
  width: fit-content;
  margin-top: 20px;
  border-radius: 20px;
  color:rgb(61, 133, 6);
  font-size: 13px;
  font-weight: 700;
  text-decoration: underline;
  padding:0px;
}
