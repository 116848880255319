.watchlist-form {
  width: 400px;
  height:auto;
  font-family: 'Courier New', Courier, monospace;
}

.watchlist-modal-title {
  font-size: 18px;
  font-weight: bold;
  margin: 20px;
  display: flex;
  justify-content: center;
}

.watchlist-form label, .watchlist-form input {
  display: flex;
  align-items: center;
  margin: 10px;
  font-weight: 650;
  color:blueviolet
}

.remove-from-list {
  color: red;
}

.buttons {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.list-confirm, .list-cancel {
  border: none;
  background: transparent;
  font-size: 16px;
  cursor: pointer;
  font-family: cursive;
  font-weight: 600;
}

.list-confirm:hover, .list-cancel:hover {
  background-color: rgb(247, 199, 199);
}
