.delete-watchlist h3 {
  font-size: 18px;
}

.delete-watchlist h4 {
  font-size: 15px;
  font-weight: bold;
  margin: 18px;
  display: flex;
  justify-content: center;
  color: red;
}


.delete-watchlist-buttons {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}

.delete-no {
  margin-left: 5px;
}

.delete-watchlist button {
  border: none;
  background: transparent;
  font-size: 16px;
  cursor: pointer;
  font-family: cursive;
  font-weight: 600;
  margin-right: 23px;
}

.delete-watchlist button:hover {
  background-color: rgb(247, 199, 199);
}
