.stock-details-whole-page {
  display: flex;
  justify-content: center;
  background-image: url('../../coins.webp');
}

.single-stock-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width:1150px;
  background-color: white;
}

.left-panel {
  margin-left: 20px;
  margin-top: 15px;
}

.result-detail {
  font-size: 32px;
  font-weight: 700;
  font-family: "Capsule Sans Display",system-ui,"Segoe UI",sans-serif;
}

.result-detail-price {
  font-size: 33px;
}

.asset-stock-details {
  display: flex;
  justify-content: flex-start;
  font-family: "Capsule Sans Display",system-ui,"Segoe UI",sans-serif;
  border: 1px solid rgba(187, 187, 230, 0.7);
  border-radius:7px;
  width:721px;
  margin-top: 10px;
  padding-bottom: 5px;
}

.asset-text {
  color:blue;
  margin-left:5px;
  font-weight: 600;
}

.bold-price {
  font-size: 21px;
  font-weight:700;
  margin-left:5px;
}

.asset-left, .asset-right {
  width: 343px;
  font-size: 17px;
}

.stock-info {
  display:flex;
  width:721px;
  margin: 10px 0px;
  font-family: "Capsule Sans Display",system-ui,"Segoe UI",Helvetica,Arial,sans-serif;
  border: 1px solid rgba(187, 187, 230, 0.7);
  border-radius:7px;
}

.stock-info-name-title, .stock-info-exchange-title, .stock-info-country-title, .stock-info-capital-title {
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 5px;
}

.stock-info-name-content, .stock-info-exchange-content, .stock-info-country-content, .stock-info-capital-content {
  font-size: 17px;
  font-weight: 500;
  color: rgb(230, 32, 32);
  padding-bottom: 5px;
}

.stock-info-left{
  width:343px;
  margin-left: 5px;
}

.overview {
  margin: 25px 0px;
  font-family: "Capsule Sans Display",system-ui,"Segoe UI",Helvetica,Arial,sans-serif;
}

.overview-title {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 10px;
}

.overview-content {
  font-size: 16px;
  font-weight: 500;
}

.show-more-buttons {
  border:none;
  background-color:transparent;
  color:rgb(0, 200, 5);
  padding:0px;
  font-family: "Capsule Sans Display",system-ui,"Segoe UI",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.news {
  margin: 35px 0px;
  font-family: "Capsule Sans Display",system-ui,"Segoe UI",Helvetica,Arial,sans-serif;
}

.news-title {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 10px;
  text-decoration: none;
}


.one-news-title {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 10px;
}

.one-news {
  margin:20px;
}

.one-news:hover {
  background-color:rgb(243, 246, 248);
}

.right-panel {
  position: fixed;
  margin-left: 780px;
}


.trade-panel {
  grid-column-start: 2;
  width:330px;
  height: 475px;
  /* border: 1px solid rgba(187, 187, 230, 0.7); */
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  margin:30px 50px 20px 0px;
  position: relative;
  font-family: "Mongolian Baiti",system-ui,"Segoe UI",sans-serif;
}

.trade-panel-title {
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 5px;
  font-size: 30px;
  font-weight: 700;
  font-family: "Capsule Sans Display",system-ui,"Segoe UI",sans-serif;
  color:rgb(0, 200, 5);
}

.transaction-switch {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.switch-button {
  border: 0.5px solid rgb(192, 204, 213);
  border-radius: 6px;
  box-shadow: rgba(55, 15, 15, 0.1) 4px 4px 3px 0px;
  height: 27px;
  padding:0px 20px;
  margin: 10px 10px 10px 0px;
  background-color: rgb(226, 213, 213);
  font-size: 16px;
  font-weight: 500;
  color: rgb(197, 130, 130);
}

.switch-button:hover {
  background-color: rgba(177, 218, 195, 0.8);
  color:rgb(255, 255, 255)
}

.errors {
  display: flex;
  justify-content: center;
  margin-top:30px;
  color:rgb(253, 2, 2);
  font-weight: 600;
  font-size: 18px;
}

.transaction-form {
  position:absolute;
  top:210px;
  left: 72px;
  font-size: 18px;
}

.current-price-text, .estimated-cost-text, .transaction-label, .cash-text {
  color:blue;
}

.transaction-current-price, .transaction-estimated-cost{
  display: flex;
}

.current-price-number, .estimated-cost-number {
  font-weight: 700;
  margin-left: 10px;
}

.share-input {
  display: flex;
  align-items:center;
  margin: 15px 15px 15px 0px;
}

.transaction-input-box {
  width:50px;
  margin-left:15px;
}

.transaction-button {
  border: 1px solid rgb(255, 80, 0);
  height:44px;
  padding:0px 48px;
  box-sizing:border-box;
  border-radius:44px;
  background-color:rgb(255, 80, 0);
  color:rgb(255, 255, 255);
  position:absolute;
  top:170px;
  left:23px;
  font-size: 15px;
  font-weight: 700;
}

.transaction-button:hover {
  background-color:rgb(255, 80, 0, 0.8);
}

.available-cash-in-trade-panel {
  display: flex;
  position:absolute;
  bottom: 10px;
  left: 65px;
  font-size: 16px;
}

.trade-panel-cash {
  margin-left: 10px;
  font-weight: 700;
}

.watchlist-container {
  width:330px;
  height: 100px;
  /* border: 0.5px solid rgb(197, 204, 224); */
  font-family: "Capsule Sans Display",system-ui,"Segoe UI",sans-serif;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.one-watchlist-title {
  display: flex;
  justify-content: center;
  margin: 15px 0px;
  padding-top: 10px;
  font-size: 20px;
  font-weight: 600;
}

.watchlist-content, .no-watchlists {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
  font-weight: 700;
}

.watchlist-container button {
  border:none;
  max-width:120px;
  height:30px;
  border-radius: 12px;
  background-color:rgb(197, 204, 224);
  padding:0px 10px;
}

.watchlist-container button:hover {
  background-color:rgb(197, 204, 224, 0.8);
}

.no-watchlists {
  font-size: 13px;
  color:rgb(250, 6, 6);
}

.stock-chart {
  width: 721px;
  height: 400px;
}

@media screen and (max-height: 739px) {
  .right-panel {
    position: absolute;
  }
}
