.nav {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 100px;
  border-bottom: 2.5px groove rgb(12, 224, 12);
}

.nav2 {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  margin:0px;
  border-bottom: 2.5px solid rgb(1, 3, 1);
}

.dropdown {
  display: block;
}

.profile-button {
  margin:20px 100px;
}

.open-drop-down-profile {
  border:none;
  border-radius: 4px;
  width:80px;
  height:30px;
  background-color: #a9ead5;
}

.open-drop-down-profile:hover {
  background-color: #8ed6c0;
}

.profile-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  padding:0px;
  margin: 5px 0px;
}

.dropdown-profile-button, .dropdown-logout-button {
  border: 1px solid #a9ead5;
  border-radius: 4px;
  background-color: white;
  font-family: "Comic Sans MS", sans-serif;
  font-size: 15px;
  font-weight: 500;
  margin-top:10px;
}

.dropdown-profile-button:hover, .dropdown-logout-button:hover {
  background-color: #cfddd9;
}

.hidden {
  display: none;
}


.login-signup {
  display: flex;
}

.login-button {

  color: yellowgreen;
  text-decoration: none;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  text-align: start;
  white-space: normal;
  cursor: pointer;
  background: rgb(0, 0, 0);
  border: 1px solid rgb(255, 199, 87);
  border-radius: 24px;
  height: 44px;
  padding: 0px 28px;
  width: 102px;
  font-family: "Comic Sans MS", sans-serif;
  margin-right: 5px;
}

.login-button:hover {
  background-color: rgb(255, 199, 87);
  color: black;
  border:1px solid rgb(0, 0, 0);
}

.signup-button {
  color: yellowgreen;
  text-decoration: none;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-align: center;
  white-space: nowrap;
  border-radius: 24px;
  cursor: pointer;
  background: rgb(0, 0, 0);
  border: 1px solid rgb(255, 199, 87);
  height: 44px;
  padding: 0px 28px;
  width: 102px;
  font-family: "Comic Sans MS", sans-serif;
  margin-right: 5px;
}

.signup-button:hover {
  background-color: rgb(255, 199, 87);
  color: black;
  border:1px solid rgb(0, 0, 0);
}

@keyframes change-size {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.title {
  font-family: "Lucida Handwriting",system-ui,"Segoe UI",sans-serif;
  font-size: 32px;
  font-weight: bolder;
  color: rgb(16, 219, 26);
  margin-left: 130px;
  text-shadow: -1px -1px 0 #350f0f, 1px -1px 0 #38ce72, -1px 1px 0 #fff, 1px 1px 0 #fff;
  animation: change-size 2s linear infinite;
}


.search-bar {
  position: relative;
  width: 300px;
  height: 40px;
  margin: 20px 0px 3px 0px;
}

.search-results-container {
  position: absolute;
}

.fa-search {
  position: absolute;
  top: 12px;
  left: 8px;
}

.search-input {
  padding-left:30px;
  margin:0px;
}

.search-results {
  display: flex;
  text-decoration: none;
  background-color: whitesmoke;
  font-size: 17px;
  font-weight: 500;
  font-family: "Capsule Sans Display",system-ui,"Segoe UI",sans-serif;
  z-index:5;
  min-width: 535px;
  justify-content: space-between;
}

.search-results:hover {
  background-color: rgb(227, 233, 237);
}

.results-symbols {
  color: rgb(2, 1, 0);
  margin-right: 30px;
}

.results-names {
  color: rgb(69, 206, 35);
  font-weight: 700;
}
