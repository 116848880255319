/* TODO Add site wide styles */

body {
  margin: 10px 0px 0px 0px;
  padding: 0;
}

li {
  list-style: none;
}

label, input {
  display: block;
  margin:5px 0px;
}

input {
  border-radius: 6px;
  border: 2px solid #ccc;
}

h1 {
  font-size: 21px;
  font-weight: 500;
}

button {
  cursor: pointer;
}
