
.main-page-whole-page {
  min-height: 81vh;
  max-height: fit-content;
  display: flex;
  justify-content: center;
  background-image: url('../../coins.webp');
}

.main-page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width:1000px;
  min-height: 100px;
  max-height: fit-content;
  display: flex;
  justify-content: center;
  background-color:rgb(249, 249, 250);
}

.left {
  display:block;
}

.total-value {
  display: block;
  height: 75px;
  margin-left: 20px;
  margin-bottom: 20px;
  padding: 50px 0px 0px 0px;
  font-size: 30px;
  font-weight: 700;
  font-family: "Capsule Sans Display",system-ui,"Segoe UI",sans-serif;
}

.total-value span {
  color: #29c602;
  font-size: 32px;
}

.chart {
  display: flex;
  justify-content: center;
}

.cash-main {
  margin-left: 20px;
  padding:24px 0px;
  font-size: 17px;
  font-weight: 700;
  font-family: "Capsule Sans Display",system-ui,"Segoe UI",sans-serif;
  /* border-bottom: 1px solid rgb(227, 233, 237); */
}

.cash-main-number {
  color: #29c602;
  font-size: 18px;
  font-weight: 700;
  font-family: "Capsule Sans Display",system-ui,"Segoe UI",sans-serif;
}

.right {
  width:330px;
  margin-right: 20px;
}

.all-asset {
  display: block;
  width:330px;
  margin-top: 50px;
  border: 1px solid rgb(227, 233, 237);
  grid-column-start:2;
}

h3 {
  display: flex;
  justify-content: flex-start;
  font-size: 15px;
  font-weight: 700;
  font-family: "Capsule Sans Display",system-ui,"Segoe UI",sans-serif;
  padding: 0px 24px;
}

a.asset-link {
  display:flex;
  text-decoration: none;
  justify-content: flex-start;
}

.one-asset {
  display: flex;
  width: 330px;
  justify-content: space-between;
  font-size: 13px;
  font-family: "Capsule Sans Display",system-ui,"Segoe UI",sans-serif;
  padding: 0px 24px;
  margin: 3px 0px 10px 0px;
  color: #0000EE
}

.one-asset:hover {
  background-color: rgb(227, 233, 237);
}

.asset-symbol {
  font-weight: 700;
}

.create-header {
  display:flex;
  justify-content: space-between;
  align-items:center;
  padding-right: 25px;
}

.create-watchlist-button {
  border:1px solid rgb(227, 233, 237);
}

.create-watchlist-button:hover {
  background-color: rgb(197, 223, 236);
}

.create-list-form {
  padding: 0px 24px;
  height:80px;
  display:flex;
}

.create-list-input {
  width:200px;
  height: 25px;
}

.create-list-buttons {
  display:flex;
  width:330px;
  justify-content: flex-start;
}

.create-list-create, .create-list-cancel {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 16px;
  border:1px solid rgb(227, 233, 237);
  padding: 0px 10px;
  margin: 10px 15px 0px 0px;
  background-color: rgb(1, 0, 5);
  color:white;
  display: flex;
  justify-content: center;
  align-items:center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;

}

.create-list-create:hover, .create-list-cancel:hover {
  background-color: rgb(59, 61, 68);
}


.one-watchlist {
  display: flex;
  justify-content: space-between;
  align-items:center;
}

.all-watchlist {
  display: block;
  width:330px;
  border: 1px solid rgb(227, 233, 237);
  font-size: 16px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.one-watchlist {
  height:35px;
  font-weight: 700;
  color: #11c38b;
  cursor: pointer;
  padding: 3px 24px;
}

.one-watchlist:hover {
  background-color: rgb(227, 233, 237);
}

.one-watchlist-name:hover {
  color:#2c9911
}

.one-watchlist button {
  border: none;
  background-color: transparent;
  color: #11c38b;
}

.one-watchlist button:hover {
  border: none;
  background-color: transparent;
  color:#2c9911
}

.list-stocks {
  border: 1px solid rgb(227, 233, 237);
}

.stock-link {
  text-decoration: none;
  font-family: "Capsule Sans Display",system-ui,"Segoe UI",sans-serif;
  font-size: 13px;
}

.one-stock {
  display: flex;
  justify-content: space-between;
  align-items:center;
  padding:10px 0px 10px 0px;
  color: #126452;
  padding: 3px 24px;
  height:35px;
}

.one-stock:hover {
  background-color: rgb(227, 233, 237);
}

.list-stock-symbol {
  font-weight: 700;
}

.social-contact {
  height:8vh;
  background-color: #add1b7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e9e9e9;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  color: #000;
  margin: 0px 10px;
}

.social-icon i {
  vertical-align: middle;
}


.main-page-footer {
  background: #0f460a;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4vh;
  font-size: 18px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
